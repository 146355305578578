import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const FaqPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("navigation.faq")} />

      <article className="article">
        <header className="article-header text--center">
          <h1 className="heading heading--md">{t("faq.header")}</h1>
        </header>

        <ul className="faq-list">
          <li>
            <h2>
              <Trans>faq.question-1</Trans>
            </h2>
            <ol>
              <li>
                <Trans>faq.answer-1-1</Trans>
              </li>
              <li>
                <Trans>faq.answer-1-2</Trans>
                <ol>
                  <li>
                    <Trans>faq.answer-1-2-a</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-2-b</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-2-c</Trans>
                  </li>
                </ol>
              </li>
              <li>
                <Trans ans>faq.answer-1-3</Trans>
              </li>
              <li>
                <Trans ans>faq.answer-1-4</Trans>
                <ol>
                  <li>
                    <Trans>faq.answer-1-4-a</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-4-b</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-4-c</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-4-d</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-4-e</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-4-f</Trans>
                  </li>
                </ol>
              </li>
              <li>
                <Trans ans>faq.answer-1-5</Trans>
              </li>
              <li>
                <Trans ans>faq.answer-1-6</Trans>
                <ol>
                  <li>
                    <Trans>faq.answer-1-6-a</Trans>
                  </li>
                  <li>
                    <Trans>faq.answer-1-6-b</Trans>
                  </li>
                </ol>
              </li>
              <li>
                <Trans ans>faq.answer-1-7</Trans>
              </li>
              <li>
                <Trans ans>faq.answer-1-8</Trans>
              </li>
              <li>
                <Trans ans>faq.answer-1-9</Trans>
              </li>
              <li>
                <Trans ans>faq.answer-1-10</Trans>
              </li>
              <li>
                <Trans ans>faq.answer-1-11</Trans>
              </li>
            </ol>
          </li>
          <li>
            <h2>
              <Trans>faq.question-2</Trans>
            </h2>
            <p>
              <Trans>faq.answer-2</Trans>
            </p>
          </li>
          <li>
            <h2>
              <Trans>faq.question-3</Trans>
            </h2>
            <p>
              <Trans>faq.answer-3</Trans>
            </p>
          </li>
          <li>
            <h2>
              <Trans>faq.question-4</Trans>
            </h2>
            <p>
              <Trans>faq.answer-4</Trans>
            </p>
          </li>
          <li>
            <h2>
              <Trans>faq.question-5</Trans>
            </h2>
            <p>
              <Trans>faq.answer-5</Trans>
            </p>
          </li>
          <li>
            <h2>
              <Trans>faq.question-6</Trans>
            </h2>
            <p>
              <Trans>faq.answer-6</Trans>
            </p>
          </li>
          <li>
            <h2>
              <Trans>faq.question-7</Trans>
            </h2>
            <p>
              <Trans>faq.answer-7</Trans>
            </p>
          </li>
        </ul>
      </article>
    </Layout>
  )
}
export default FaqPage
